<template>
  <el-container class="nav-container">
    <el-header style="position: fixed;top: 0;left:0;z-index: 100;width: 100%;">
      <div>
        <img id="logo" src="../assets/img/head_logo.png" @click="$router.push('/')" style="cursor:pointer" alt="">
        <el-menu
            class="lg"
            :default-active="$route.path"
            mode="horizontal"
            background-color="#151924"
            text-color="#A9A9AD"
            router
            active-text-color="#FFFFFF">
          <el-menu-item index="/">首頁</el-menu-item>
          <el-menu-item index="/user_center">用戶中心</el-menu-item>
          <el-menu-item index="/robot">機器人中心</el-menu-item>
          <el-menu-item index="/rebate">返傭中心</el-menu-item>
          <el-menu-item index="/safe">安全中心</el-menu-item>
        </el-menu>
      </div>
      <div class="icons">
<!--        <img class="img-sm" @click="torobot" style="width: 24px;height: 24px" src="../assets/img/center/robot.svg" alt="">-->
        <img class="img-sm" @click="drawer = true" style="margin-right: 0;width: 24px;height: 24px;" src="../assets/img/center/navigation.svg" alt="">
<!--        &lt;!&ndash;        <img class="img-lg" src="../assets/img/center/center.png" alt="">&ndash;&gt;-->
<!--        &lt;!&ndash;点击图标弹出退出菜单&ndash;&gt;-->
<!--        <el-dropdown trigger="click">-->
<!--          <span class="el-dropdown-link">-->
<!--            <img class="img-lg" src="../assets/img/center/center.png" alt="" style="margin-top: 5px">-->
<!--          </span>-->
<!--          <el-dropdown-menu slot="dropdown">-->
<!--            <div @click="handleToExit">-->
<!--              <el-dropdown-item style="display: flex;align-items: center;justify-content: center;width: 60px;height: 40px"><img style="width:14px;margin-right: 5px" src="../assets/img/center/drawer_exit.svg" alt=""><span> 退出</span></el-dropdown-item>-->
<!--            </div>-->
<!--          </el-dropdown-menu>-->
<!--        </el-dropdown>-->
<!--        <span class="img-lg" style="font-size: 13px;color: #A9A9AD;margin: 2px 4px 0 -16px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;height: 17px">{{userNavName}}</span>-->
<!--        <img class="img-lg" v-if="isVip" src="../assets/img/VIP.svg" alt="" style="margin-right: 20px;margin-top: 2px">-->
<!--        <img class="img-lg" v-else src="../assets/img/SVIP.svg" alt="" style="margin-right: 20px">-->
<!--        <img class="img-lg" src="../assets/img/center/found.png" alt="">-->
      </div>
    </el-header>
    <div style="margin-bottom: 60px"></div>
    <!--      抽屉-->
    <el-drawer
        size="60%"
        :visible.sync="drawer"
        :show-close="true">
      <div class="drawer">
        <el-menu
            class="sm-menu"
            :default-active="$route.path"
            mode="vertical"
            @select="handleSelectDrawer"
            text-color="#000613"
            router
            active-text-color="#AB54DB">
<!--          <div class="user-icons-info" style="margin: 0px 0 10px -30px">-->
<!--            <img src="../assets/img/avatar.png" alt="">-->
<!--            <div>{{username}}</div>-->
<!--            <img v-if="isVip" src="../assets/img/VIP.svg" alt="">-->
<!--            <img v-else src="../assets/img/SVIP.svg" alt="">-->
<!--          </div>-->
          <el-menu-item index="/user_center"><img style="width: 24px;height: 24px;margin-right: 6px" :src="require('../assets/img/center/drawer_user'+(this.$route.path==='/user_center' ? '_active' : '')+'.svg')" alt=""><span>用户中心</span></el-menu-item>
          <el-menu-item index="/rebate"><img style="width: 24px;height: 24px;margin-right: 6px" :src="require('../assets/img/center/drawer_rebate'+(this.$route.path==='/rebate' ? '_active' : '')+'.svg')" alt=""><span>返佣中心</span></el-menu-item>
          <el-menu-item index="/safe"><img style="width: 24px;height: 24px;margin-right: 6px" :src="require('../assets/img/center/drawer_safe'+(this.$route.path==='/safe' ? '_active' : '')+'.svg')" alt=""><span>安全中心</span></el-menu-item>
          <el-menu-item index="/robot"><img style="width: 24px;height: 24px;margin-right: 6px" :src="require('../assets/img/center/drawer_robot'+(this.$route.path==='/robot' ? '_active' : '')+'.svg')" alt=""><span>机器人中心</span></el-menu-item>
          <el-menu-item @click="handleToExit"><img style="width: 20px;height: 20px;margin-right: 6px" :src="require('../assets/img/center/drawer_exit'+(this.$route.path==='/exit' ? '_active' : '')+'.svg')" alt=""><span>退出</span></el-menu-item>
        </el-menu>
      </div>
    </el-drawer>

  </el-container>
</template>

<script>

export default {
  name: "Navbar",
  data(){
    return {
      drawer:false,
      lgShow:false,
      smShow:false,
      //  是否设置名字
      username:'',
      //  是否是vip
      isVip:false,
      userInfo:{}
    }
  },
  created() {
    this.changeScreenSize()
  },
  mounted() {
    this.changePage()
    // this.changeOffset()
  },
  computed:{
    userNavName(){
      if (this.userInfo==null) return
      const {name,mobile,username}=this.userInfo
      if (name!==''&&name!==null) return name
      else if(mobile!==null&&mobile!=='') return mobile
      else return username
    }
  },
  methods:{
    handleAside(e){
      document.documentElement.scrollTop = e;
    },
    changeOffset(id='robot-earn'){
      window.addEventListener('scroll',()=>{
      })
    },
    changeScreenSize(){
      window.addEventListener('resize',()=>{
        const bodyWidth=document.body.clientWidth
        if (bodyWidth>=1079) {
          this.lgShow = true
          this.smShow=false
          return
        }
        this.smShow=true
        this.lgShow=false
      })
      const bodyWidth=document.body.clientWidth
      if (bodyWidth>=1079) return this.lgShow = true
      this.smShow=true
    },
    changePage(){
      this.getUserInfo()
      const bodyWidth=document.body.clientWidth
      if (bodyWidth<1079) return
    },
    torobot(){
      this.$router.push('/robot')
    },
    handleSelectDrawer(){
      this.drawer=false
    },
    //  执行退出登录
    handleToExit(){
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('userInfo')
      this.$router.replace('/')
      this.$message.success('退出登录成功 !')
    },
    //  获取用户信息
    getUserInfo(){
      const userInfo=window.localStorage.getItem('userInfo')
      this.userInfo=JSON.parse(userInfo)
      if (userInfo) {
        const {name,mobile,charge_style2,username} = JSON.parse(userInfo)
        name===null||name===''? this.username=mobile:this.username=name
        if ((mobile===null||mobile==='')&&(name===null||name==='')) this.username=username
        charge_style2===2 ?this.isVip=false :this.isVip=true
      }
    }
  },

  watch:{
    $route:{
      // immediate:true,
      handler:'changePage'
    },
  }
}
</script>

<style scoped>
/*.nav-container{*/
/*  height: 100%;*/
/*}*/
.el-header{
  background-color: #393948;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
}
.el-header .el-menu-item{
  font-size: 16px;
  padding: 0 10px;
  margin: 0 20px;
}
.el-header>div{
  display: flex;
  align-items: center;
  color: #fff;
}
.el-header .icons{
  margin-right: 136px;
  cursor: pointer;
}
.el-header .icons img:first-child,.el-header .icons img:nth-child(3){
  margin-right: 24px;
}
#logo{
  width: 72px;
  height: 22px;
  margin-left: 132px;
  margin-right: 36px;
}
.el-aside{
  background-color: #FFFFFF;
}
.el-main{
  background-color: #F7F9FC;
  padding-bottom: 0 !important;
}
.el-menu{
  border: none;
}
.lg.el-menu--horizontal>.el-menu-item.is-active{
  border-bottom: 3px solid #AB54DB !important;
}
.menu-vertical .el-menu-item{
  box-sizing: border-box;
  padding-left: 36px !important;
}
.menu-vertical .el-menu-item.is-active{
  border-right: 2px solid #AB54DB !important;
}
.lg{
  position: relative;
}
.el-footer{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 279px !important;
  background-color: #151924;
}
@media screen and (min-width:1079px ){
  .lg,.img-lg{
    display: flex;
  }
  .img-lg{
    display: inline-block;
  }
  .sm,.img-sm{
    display: none;
  }
  .img-sm{
    display: none;
  }
}
.el-main{
  margin-bottom: 279px;
}
@media screen and (max-width: 1079px){
  .lg{
    display: none;
  }
  .img-lg{
    display: none;
  }
  .sm{
    display: block;
    background-color: #F7F9FC;
    /*height: 100%;*/
  }
  .img-sm{
    display: inline-block;
  }
  #logo{
    margin-left: 16px;
  }
  .el-header .icons{
    margin-right: 0px;
  }
  .sm-menu{
    background-color: #FFFFFF;
  }

  .sm .el-menu{
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
  }
  .sm .el-menu .el-menu-item img{
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }

}
.user-icons-info{
  text-align: center;
  width: 100%;
  margin: 48px 0;
}
.user-icons-info img:first-child{
  width: 88px;
  height: 88px;
}
.user-icons-info div{
  margin: 16px 0 4px 0;
  font-size: 18px;
  font-weight: 500;
  color: #000613;
}
.user-icons-info img:nth-child(3),.user-icons-info img:nth-child(4){
  /*margin-right: 10px;*/
}

/**
 抽屉的header的边距
 */
/deep/.el-drawer__header {
  margin-bottom: 0;
  padding: 20px 20px 0;
}
</style>
